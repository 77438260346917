import { api } from '../api/api';

export const authAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    jwtVerify: builder.mutation({
      query: (credentials) => ({
        url: "/auth/jwt/verify/",
        method: "POST",
        body: credentials, // {'token':null}
      }),
    }),
    jwtCreate: builder.mutation({
      query: (credentials) => ({
        url: "/auth/jwt/create/",
        method: "POST",
        body: credentials, // {'email':null, 'password':null}
        credentials: 'include',
      }),
    }),
    jwtRefresh: builder.mutation({
      // not used, directly called with noAuthQuery
      query: (credentials) => ({
        url: "/auth/jwt/refresh/",
        method: "POST",
        body: credentials, // {'refresh':null}
        credentials: 'include',
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/users/reset_password/",
        method: "POST",
        body: credentials, // {'email':null}
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: (credentials) => ({
        url: "/auth/users/reset_password_confirm/",
        method: "POST",
        body: credentials, // {'uid':null,'token':null,'new_password':null,'re_new_password':null}
      }),
    }),
    createUser: builder.mutation({
      query: (credentials) => ({
        url: "/auth/users/",
        method: "POST",
        body: credentials, // {'email':null,'name':null,'password':null,'re_password':null}
      }),
    }),
    activateUser: builder.mutation({
      query: (credentials) => ({
        url: "/auth/users/activation/",
        method: "POST",
        body: credentials, // {'uid':null,'token':null}
      }),
    }),
    resendActivationEmail: builder.mutation({
      query: (args) => ({
        url: "/auth/users/resend_activation/",
        method: "POST",
        body: args, // {'email':null}
      }),
    }),
    getIsActive: builder.query({ // get is_active of the specified user
      query: (args) => ({
        url: '/auth/account/',
        params: args, // {'email': email}
      }),
    }),
    getUserProfile: builder.query({ // get profile of the logged in user
      query: (args) => ({
        url: '/auth/profile/',
      }),
    }),
    getPublicInfo: builder.query({ // get profile of the logged in user
      query: (args) => ({
        url: '/auth/public_info/',
      }),
    }),
  }),
});

export const {
  useJwtVerifyMutation,
  useJwtCreateMutation,
  useJwtRefreshMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useCreateUserMutation,
  useActivateUserMutation,
  useResendActivationEmailMutation,
  useLazyGetIsActiveQuery,
  useLazyGetUserProfileQuery,
  // useGetPublicInfoQuery,
  useLazyGetPublicInfoQuery,
} = authAPI;
