import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'

import './App.css'
import Urls from './Urls'
import Footer from "./components/layout/Footer"
import Header from "./components/layout/Header"
import { theme } from './theme'


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box className="App bg-white-gradient text-dark">
        <CssBaseline />
        <Header />
        <Urls />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App
