import { Box, Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import logo from "../../assets/images/header-logo.svg"
import { useActivateUserMutation } from "../../features/auth/authAPI"


const Activation = () => {
  const { uid, token } = useParams();
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const handleClickGoHome = () => {
    navigate('/login');
  };

  const [activateUserMutation] = useActivateUserMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await activateUserMutation({ uid: uid, token: token }).unwrap();
        setErrMsg('');
      } catch (err) {
        console.log(err)
        if (!err?.data) {
          setErrMsg('No Server Response!');
        } else {
          let errMsgTemp = '';
          if (err.data.uid) {
            errMsgTemp = errMsgTemp + err.data.uid;
          }
          if (err.data.token) {
            errMsgTemp = errMsgTemp + err.data.token;
          }
          setErrMsg(errMsgTemp);
        }
      }
    };
    fetchData();
  }, [uid, token, activateUserMutation]);

  return (
    <Box sx={{ mt: 5 }}>
      {/* <img src={logo} alt="logo" width="300" /> */}
      {!errMsg ? (
        <Box>
          <Typography variant="h6" color='neutral.main' mb={5} mt={5}>
            Your account has been successfully activated.
          </Typography>
          <Button variant="contained" size='medium' position='relative' color="primary" onClick={handleClickGoHome}>
            Go to Login
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ fontSize: 18 }} mt={10} color="ra_error.main">{errMsg}</Typography>
          <Typography component="h4" variant="h6" color="neutral.main" mt={2} mb={5}>An error occurred, please try again.</Typography>
          <Button sx={{ mb: 5 }} variant="contained" size='medium' position='relative' color="primary" onClick={handleClickGoHome}>
            Go to Login
          </Button>
        </Box>
      )}
    </Box>
  )
};

export default Activation
