import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar, Avatar, Box, Button, Container, Divider, IconButton, Link, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import { useLazyGetPublicInfoQuery, useLazyGetUserProfileQuery } from '../../features/auth/authAPI';

import { logOut, setUser, updatePublicInfo } from '../../features/auth/authSlice';

const pages = ['Home', 'DownPay 25%', 'DownPay 30%', 'DownPay 50%', 'All Cash', 'Contact'];
const pageNav = ['/home', 'downpay_25', 'downpay_30', 'downpay_50', 'downpay_100', '/contact'];
const settings = ['Profile', 'Logout'];


export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userID = useSelector((state) => state.auth.userID);
  const [getProfile] = useLazyGetUserProfileQuery();
  const [getPublicInfo] = useLazyGetPublicInfoQuery();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function nameInitial(name) {
    const separators = [' ', '_', ', '];
    const split = name.split(new RegExp(separators.join('|'), 'g'));
    const first = split[0][0];
    const last = split.length > 1 ? split[split.length - 1][0] : '';
    return `${first}${last}`
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: nameInitial(name).toUpperCase(),
    };
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserClick = (setting) => () => {
    if (setting === 'Logout') {
      dispatch(logOut());
      window.location.href = '/login';
    }
    handleCloseUserMenu();
  };

  const handleMenuClick = (i) => () => {
    window.location.href = pageNav[i];
    handleCloseNavMenu();
  };

  const getUserProfile = async () => {
    try {
      const userProfile = await getProfile().unwrap();
      dispatch(setUser({ user: userProfile }));
    } catch (e) {
      if (e.status === 403) alert('The trial has ended for the account.');
      dispatch(setUser({ user: null }));
    }
  };

  React.useEffect(() => {
    if (userID) {
      getUserProfile();
    }
  }, [userID]);

  const getPublicInfoFunc = async () => {
    try {
      const res = await getPublicInfo().unwrap();
      if (res.state === 1) {
        dispatch(updatePublicInfo({ updated: res.result }));
      }
    } catch (e) {
      if (e.status === 403) alert('The trial has ended for the account.');
    }
  }

  React.useEffect(() => {
    getPublicInfoFunc();
  }, []);

  return (
    <Stack direction='column'>
      <AppBar position="static" color="cat_black" sx={{ opacity: 0.6 }}>
        <Stack direction='row' alignItems='center' sx={{ width: '100%', justifyContent: 'center' }}>
          <Typography color='white' textAlign="center" sx={{ my: 0.5, ml: 5 }}>This site is currently under Early Access, please report issues &nbsp;</Typography>
          <Link sx={{ input: { cursor: 'pointer' } }} href='mailto:vogorealty02@gmail.com' target="_blank">here</Link>
        </Stack>
      </AppBar>
      <AppBar position="static" color="primary">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon color='ra_white' />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, i) => (
                  <MenuItem key={page} onClick={handleMenuClick(i)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, i) => (
                <Button
                  key={page}
                  onClick={handleMenuClick(i)}
                  sx={{ my: 1, mx: 1, color: 'white', display: 'block', fontSize: 16 }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            {user ? <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar {...stringAvatar(user.name)} />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Typography mx={1} fontSize='1rem' fontWeight='bold'>{user.name}</Typography>
                <Divider />
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleUserClick(setting)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> : <Button
              key='Login'
              onClick={() => { window.location.href = '/login'; handleCloseNavMenu(); }}
              sx={{ flexGrow: 0, my: 1, color: 'white', display: 'block', fontSize: 16 }}
            >
              Login
            </Button>}
          </Toolbar>
        </Container>
      </AppBar >
    </Stack>
  );
}