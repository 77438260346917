import { Box, Divider, Typography } from '@mui/material';
import React from "react";
import { useSelector } from 'react-redux';

const Footer = () => {
  const publicInfo = useSelector((state) => state.auth.publicInfo);

  return (
    <Box sx={{ position: 'relative', left: '50%', width: '100%', transform: 'translate(-50%, 0%)', mt: 1 }}>
      <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, px: 5 }} />
      <Typography pt={1}>
        © Last update at {publicInfo.time_updated}, VOGO Realty
      </Typography >
    </Box >
  );
};
export default Footer;
