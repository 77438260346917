import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Stack, Box, Container, Card, CardMedia, InputAdornment, CardContent, Grid, Button, TextField, Typography } from "@mui/material"
import { LockOutlined } from "@mui/icons-material"

import logo from "../../assets/images/header-logo.svg"
import { useResetPasswordConfirmMutation } from "../../features/auth/authAPI"


const PasswordResetConfirm = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [errData, setErrData] = useState({});
  const { uid, token } = useParams();

  const [resetPasswordConfirmMutation] = useResetPasswordConfirmMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    const data = new FormData(e.currentTarget);
    const formData = {
      uid: uid,
      token: token,
      new_password: data.get('new_password'),
      re_new_password: data.get('re_new_password'),
    };
    try {
      console.log(formData)
      await resetPasswordConfirmMutation(formData).unwrap();
      navigate("/password/reset/success", { state: { pathFrom: '/password/reset/confirm/' } });
    } catch (err) {
      console.log(err)
      if (!err?.data) {
        setErrMsg("No Server Response");
      } else {
        setErrData(err.data);
      }
    }
  };

  return (
    <Box sx={{ top: '50%', pt: '20vh' }}>
      <Container maxWidth="xs">
        <Card
          sx={{ px: 2, mt: 0, backgroundColor: "transparent", opacity: 1, boxShadow: 'none' }}
          elevation={0}
        >
          {/* <CardMedia component="img" height={55} image={logo} style={{ scale: '92%' }} /> */}
          <CardContent>
            <Stack direction='column' width="100%" alignItems="flex-start">
              <Typography mt={1} component="h4" variant="h5" fontWeight='bold'>
                Reset Password
              </Typography>
              <Typography mb={2} mt={1} fontSize={16} color="neutral.main">
                Enter a new password.
              </Typography>
            </Stack>
            <Box component="form" position='relative' noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errData?.new_password && (<Typography sx={{ mt: -2.5, left: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.new_password}</Typography>)}
                  <TextField
                    required
                    fullWidth
                    name="new_password"
                    label="Password"
                    type="password"
                    id="new_password"
                    autoComplete="new-password"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><LockOutlined /></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {errData?.re_new_password && (<Typography sx={{ mt: -2.5, left: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.re_new_password}</Typography>)}
                  <TextField
                    required
                    fullWidth
                    name="re_new_password"
                    label="Confirm Password"
                    type="password"
                    id="re_new_password"
                    autoComplete="off"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><LockOutlined /></InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
              {errMsg && (<Typography sx={{ mb: -2, fontSize: 13 }} color="ra_error.main">111{errMsg}</Typography>)}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size='large'
                sx={{
                  mt: 2
                }}
              >
                Reset Password
            </Button>
              {errData?.non_field_errors && (<Typography sx={{ fontSize: 13 }} color="ra_error.main">{errData.non_field_errors}</Typography>)}
              {errData?.token && (<Typography sx={{ fontSize: 13 }} color="ra_error.main">{errData.token}</Typography>)}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PasswordResetConfirm
