import { api } from '../api/api';

export const investAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllReturnRate: builder.query({ // get is_active of the specified user
            query: (args) => ({
                url: '/invest/rate/',
                params: args, // {'downRate': downRate}
            }),
        }),
        searchProperty: builder.query({ // get is_active of the specified user
            query: (args) => ({
                url: '/invest/search/',
                params: args, // {'address': address}
            }),
        }),
    }),
});

export const {
    useLazyGetAllReturnRateQuery,
    useLazySearchPropertyQuery,
} = investAPI;
