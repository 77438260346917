import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Select, Stack, Tab, Tooltip, Typography, useTheme } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropertyMap from "../../components/invest/PropertyMap";

function InvestGuide(props) {
    const { downRate } = props;

    return (
        <div><PropertyMap downRate={downRate} /></div>
    )
}

export default InvestGuide