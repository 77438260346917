// parse jwt token information
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

// check if jwt has expired
const jwtExpired = (token) => {
    try {
        const info = JSON.parse(atob(token.split(".")[1]));
        return Date.now() / 1000 > info.exp;
    } catch (e) {
        return true;
    }
};

export { jwtExpired, parseJwt };

