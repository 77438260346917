import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, Stack, Tab, Tooltip, Typography, useTheme } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: yellow;
  opacity: 0.6;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
`;

function MapMarker(props) {
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <>
      <StyledMarker onClick={() => setShowInfo((prev) => !prev)} />
      {showInfo && <Paper style={{ background: 'white', minWidth: 200 }} >
        <strong>PropertyID: {props.ID}</strong><br />
        <li>Address: {props.info.Address}</li>
        <li>2019 Property Tax Rate: {props.info['2019Rate']}</li>
        <li>2019 Appraisal: {props.info['2019']}</li>
        <li>2018 Appraisal: {props.info['2018']}</li>
        <li>2017 Appraisal: {props.info['2017']}</li>
        <li>2016 Appraisal: {props.info['2016']}</li>
        <li>2015 Appraisal: {props.info['2015']}</li>
      </Paper > }
    </>
  )
}

export default MapMarker