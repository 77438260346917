import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtExpired } from '../../features/utils/jwt';


const RequireAuth = () => {
    // In the case that an api call failed because of authorization and the refresh also failed, system will logout and set token to null
    // So if token exists, it means that the authentication is valid, then the user can pass the protected route layer
    // With this, if the user close the window after logging in and want to open it again shortly, logging in is not required
    // This behavior is common in many websites
    const token = useSelector((state) => state.auth.access);
    const expired = token ? jwtExpired(token) : true;
    const location = useLocation();

    return (
        token && !expired ? <Outlet /> : <Navigate to="/login" state={{ pathname: location.pathname }} replace />
    )
};

export default RequireAuth;
