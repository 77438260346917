import { createSlice } from '@reduxjs/toolkit';

// dummy not used yet
export const investSlice = createSlice({
    name: 'invest',
    initialState: { downRate: 0.25 },
    reducers: {
        updateDownRate: (state, action) => {
            const { updated } = action.payload;
            state.downRate = updated;
        },
    },
});

export const { updateDownRate } = investSlice.actions;

export default investSlice;
