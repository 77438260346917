import { Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Select, Stack, Tab, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const options = [
    // {
    //     value: 'owner',
    //     label: 'Owner',
    // },
    {
        value: 'address',
        label: 'Addr',
    },
    // {
    //     value: 'neighborhoodCode',
    //     label: 'NCode',
    // },
];

function SearchBox(props) {
    const { handlesubmit, ...others } = props
    const [search, setSearch] = React.useState('');
    const [type, setType] = React.useState('address');

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    return (
        <Box {...others}>
            <TextField
                select
                value={type}
                InputProps={{
                    style: { fontSize: 14, backgroundColor: "white" },
                }}
                sx={{
                    "& .MuiInputBase-root": {
                        height: 40,
                        width: 80,
                    },
                }}
                onChange={handleChangeType}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                variant="outlined"
                InputProps={{
                    readOnly: false,
                    style: { fontSize: 16, backgroundColor: "white" },
                }}
                label="Search"
                value={search}
                onChange={handleChange}
                size='small'
                name='search'
                sx={{ mr: 0 }}
            />
            <Button type="submit" color="primary" variant="outlined" sx={{ height: 39 }} onClick={handlesubmit(type, search)}>
                Submit
            </Button>
        </Box>
    )
}

export default SearchBox