import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';


export const authSlice = createSlice({
  name: 'auth',
  initialState: { userID: null, access: null, emailResendCountdown: 0, user: null, publicInfo: {} },
  reducers: {
    setCredentials: (state, { payload: { userID, access, refresh } }) => {
      state.userID = userID;
      state.access = access;
      Cookies.set('refresh', refresh, { expires: 7 }); // expires in 7 days from now
    },
    logOut: (state) => {
      state.userID = null;
      state.access = null;
      state.user = null;
      Cookies.set('refresh', null);
    },
    updateEmailResendCountdown: (state, action) => {
      const { updated } = action.payload;
      state.emailResendCountdown = updated;
    },
    setUser: (state, { payload: { user } }) => {
      state.user = user;
    },
    updatePublicInfo: (state, action) => {
      const { updated } = action.payload;
      state.publicInfo = updated;
    },
  },
});

export const { setCredentials, logOut, updateEmailResendCountdown, setUser, updatePublicInfo } = authSlice.actions;

export default authSlice;
