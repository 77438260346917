import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import { api } from './features/api/api'
import authSlice from './features/auth/authSlice'
import investSlice from './features/invest/investSlice'


const persistConfig = {
  key: 'root',
  storage,
  blacklist: [api.reducerPath],
  stateReconciler: autoMergeLevel2,
};

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authSlice.reducer,
  invest: investSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware)
});