import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

/* login pages  */
import RemoveAuth from './components/auth/RemoveAuth';
import RequireAuth from './components/auth/RequireAuth';
import Activation from "./pages/auth/Activation";
import ActivationEmailSent from "./pages/auth/ActivationEmailSent";
import Login from './pages/auth/Login';
import NotFound from './pages/auth/NotFound';
import PasswordReset from "./pages/auth/PasswordReset";
import PasswordResetConfirm from "./pages/auth/PasswordResetConfirm";
import PasswordResetEmailSent from "./pages/auth/PasswordResetEmailSent";
import PasswordResetSuccess from "./pages/auth/PasswordResetSuccess";
import Signup from "./pages/auth/SignUp";

/* app pages */
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import InvestGuide from "./pages/invest/InvestGuide";


function Urls() {
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route element={<RemoveAuth />}>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/email_sent/activate" element={<ActivationEmailSent />} />
          <Route path="/activate/:uid/:token" element={<Activation />} />
          <Route path="/password/reset" element={<PasswordReset />} />
          <Route path="/email_sent/reset_password" element={<PasswordResetEmailSent />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<PasswordResetConfirm />} />
          <Route path="/password/reset/success" element={<PasswordResetSuccess />} />
        </Route>

        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="/downpay_25" element={<InvestGuide downRate={0.25} />} />
          <Route path="/downpay_30" element={<InvestGuide downRate={0.3} />} />
          <Route path="/downpay_50" element={<InvestGuide downRate={0.5} />} />
          <Route path="/downpay_100" element={<InvestGuide downRate={1} />} />
        </Route>

        {/* others */}
        <Route element={<RemoveAuth />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
};

export default Urls;