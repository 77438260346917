import { Box } from "@mui/material";
import React from "react";

import LoginCard from "../../components/auth/LoginCard";

function Login(props) {
  return (
    <Box sx={{ top: '50%', pt: '20vh' }}>
      <LoginCard {...props} />
    </Box>
  );
}

export default Login
