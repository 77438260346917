import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#235D9F',
            light: '#AFE0F8',
            lighter: '#E6F3FA',
            contrastText: "#fff",
            c50: '#F7FEFE',
            c100: '#D6F1FB',
            c200: '#AFE0F8',
            c300: '#82C3EA',
            c400: '#5FA2D5',
            c500: '#3178B9',
            c600: '#235D9F',
            c700: '#184685',
            c800: '#0F316B',
            c900: '#092258',
            alpha: {
                c100: '#d6f1fb1a',
                c200: '#afe0f81a',
                c300: '#82c3ea33',
                hover: '#235d9f1a',
                press: '#235d9f33',
                drag: '#235d9fb3',
                c700: '#1846851a',
                c800: '#0f316b33'
            }
        },
        secondary: {
            main: '#E1EBF4',
            light1: '#F1F4F6',
            light: '#484848',
            ligher: '#eee',
            contrastText: "#fff"
        },
        foreground: {
            main: '#242A2D',
            text: '#5D6063',
            textdisabled: '#999CA0',
            textprimary0: '#000000',
            textprimary1: '#00000059',
            textprimary2: '#00000080',

        },
        neutral: {
            main: '#233D4D',
            contrastText: '#fff',
            dark: '#000',
        },
        ra_error: {
            main: '#CD163F',
            background: "#FADFE1",
            contrastText: "#fff"
        },
        ra_warning: {
            main: '#F58025',
            contrastText: "#fff"
        },
        ra_info: {
            main: '#757575',
            light: '#E2E2E2',
            contrastText: "#fff"
        },
        ra_healthy: {
            main: '#268605',
            contrastText: "#fff"
        },
        cat_yellow: {
            main: '#FFC500',
            heavy: '#ffab00',
            contrastText: "#fff"
        },
        cat_black: {
            main: '#000000',
            light: '#cfcfcf'
        },
        ra_blue: {
            main: '#003E7E',
            light: '#6495ED',
            lighter: '#CBECFB',
            mld1: '#8BADBB',
            contrastText: "#fff"
        },
        ra_red: {
            main: '#CD163F',
            bgLight: '#FDF7FB',
            light: '#ffdd4b',
            contrastText: "#fff"
        },
        ra_black: {
            main: '#000',
            contrastText: "#fff"
        },
        ra_green: {
            main: '#008000',
            contrastText: "#fff"
        },
        ra_white: {
            main: '#fff',
            contrastText: "#000"
        },
        background: {
            main: '#F1F4F6',
            white: '#FFFFFF',
            gray: '#B8B8B8',
            c1: '#FBFDFD',
            c2: '#FFFFFF',
            c3: '#F8FBFC',
            c4: '#F1F4F6',
            c5: '#E8EBED',
            c6: '#DBDFE2',
            c7: '#B8BCBF',
            c8: '#3E4144',
            alt01: '#C9D5DD',
            alt02: '#889CAC',
            alt03: '#999CA0',
            inactive: '#5D6063',
            inactivehover: '#707477',
            progress: '#57D9A3',
            alpha: {
                c10: '#DBDFE21A',
                c20: '#3E414433',
                c30: '#889CAC33',
                disable: '#999CA03D',
                hover: '#7074771A'
            }
        },
        ra_tolabel: {
            main: '#D38C00',
            background: "#FAEFDF",
            contrastText: "#000"
        },
    },
    typography: {
        fontFamily: "Barlow",
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
    },
});