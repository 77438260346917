import { LockOutlined, PersonOutlineOutlined, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography, useTheme } from "@mui/material"
import React from "react"
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import header_logo from "../../assets/images/header-logo.svg"
import { useJwtCreateMutation, useLazyGetIsActiveQuery, useResendActivationEmailMutation } from '../../features/auth/authAPI'
import { setCredentials } from '../../features/auth/authSlice'
import { parseJwt } from '../../features/utils/jwt'
import { DEMO_VERSION } from '../../settings'


function LoginCard() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jwtCreate, { isLoading: isLoadingLogin }] = useJwtCreateMutation();
  const [resendActivationEmailMutation] = useResendActivationEmailMutation();
  const [getIsActive] = useLazyGetIsActiveQuery();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    setErrMsg('');
  }, [values.email, values.password])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickForgotPassword = () => {
    navigate('/password/reset')
  };
  const handleClickSignUp = () => {
    navigate('/signup')
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setErrMsg('');
      const token = await jwtCreate({ email: values.email, password: values.password }).unwrap();
      const userID = parseJwt(token.refresh).user_id;
      dispatch(setCredentials({ userID: userID, access: token.access, refresh: token.refresh }));
      navigate(location.state?.pathname ? location.state.pathname : '/home');
    } catch (err) {
      if (e.status === 403) alert('The trial has ended for the account.');
      console.log(err)
      if (!err?.data?.detail) {
        setErrMsg('No Server Response');
      } else {
        setErrMsg(err.data?.detail);
        // for the case that activation failed
        const user = await getIsActive({ email: values.email }).unwrap();
        if (user && !user.is_active) {
          setErrMsg('User exists but is not activated!');
          await new Promise(r => setTimeout(r, 2000));
          navigate("/email_sent/activate", { state: { email: values.email } });
        }
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography sx={{ fontSize: 26, fontWeight: 'bold' }} color="ra_black.main">Login</Typography>
      <Typography sx={{ fontSize: 18 }} color="ra_black.main">Use a local account to login.</Typography>
      <Card
        sx={{ px: 2, mt: 0, backgroundColor: "transparent", opacity: 1, boxShadow: 'none' }}
        elevation={0}
      >
        <CardContent>
          <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, px: 0 }} />
          <Box noValidate mb={2} mt={2} position='relative'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {errMsg && (<Typography sx={{ mt: 19, left: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errMsg}</Typography>)}
                <TextField
                  id='email_input'
                  variant="outlined"
                  required
                  fullWidth
                  type="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange("email")}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><PersonOutlineOutlined /> </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" required fullWidth>
                  <InputLabel htmlFor="password_input">Password</InputLabel>
                  <OutlinedInput
                    id="password_input"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    startAdornment={<InputAdornment position="start"><LockOutlined /></InputAdornment>}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="end">
              <Button
                disableRipple
                size="small"
                color="neutral"
                onClick={handleClickForgotPassword}
              >Forgot Password?</Button>
            </Stack>
            <Button fullWidth type="submit" variant="contained" sx={{
              background: theme.palette.primary.main,
              mt: 2
            }} onClick={handleLogin}
              disabled={isLoadingLogin}
            >Sign in</Button>
            {isLoadingLogin && (<CircularProgress size={24} sx={{
              color: 'primary.main',
              position: 'absolute',
              top: 236,
              left: 150,
              my: -5,
            }}
            />)}
          </Box>
          <Stack mt={-1} direction="row" justifyContent="center">
            <Button
              disableRipple
              size="small"
              color="neutral"
              disabled
              sx={{ mr: -1 }}
            >New to VOGO?</Button>
            <Button
              disableRipple
              size="small"
              color="neutral"
              onClick={handleClickSignUp}
            >Sign Up</Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}

export default LoginCard;
