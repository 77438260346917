import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Box, Typography, Button } from "@mui/material"

import logo from "../../assets/images/header-logo.svg"


function PasswordResetEmailSent() {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClickGoHome = () => {
    navigate('/login');
  };

  return (
    (location.state !== null && location.state !== undefined) ? <Box sx={{ top: '50%', pt: '20vh' }}>
      {/* <img src={logo} alt="logo" width="300" /> */}
      <Typography component="h4" variant="h5" color='neutral.main' fontWeight='bold' mt={2}>
        Check your email
      </Typography>
      <Typography variant="h6" color='neutral.main' mt={2}>
        If an active account associates with this email, we will send a password reset link to
      </Typography>
      <Typography variant="h6" color='neutral.dark' fontWeight='bold' mt={2}>
        {location.state?.email}
      </Typography>
      <Typography variant="h6" color='neutral.main' mt={2}>
        Click the link to reset your password.
      </Typography>
      <Button sx={{ mt: 4 }} variant="contained" size='position' position='relative' color="primary" onClick={handleClickGoHome}>
        Go to Login
      </Button>
    </Box> : <Navigate to="/login" replace />
  )
}

export default PasswordResetEmailSent
