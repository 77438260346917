import React from 'react'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, CardMedia, Box, Container, Card, CardContent, Grid, Button, TextField, Typography, Divider, InputAdornment } from "@mui/material"
import { EmailOutlined } from "@mui/icons-material"

import logo from "../../assets/images/header-logo.svg"
import { updateEmailResendCountdown } from "../../features/auth/authSlice"
import { useResetPasswordMutation, useLazyGetIsActiveQuery } from "../../features/auth/authAPI"


const PasswordReset = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailResendCountdown = useSelector((state) => state.auth.emailResendCountdown);
  const [errMsg, setErrMsg] = React.useState('');

  const [resetPasswordMutation] = useResetPasswordMutation();
  const [getIsActive] = useLazyGetIsActiveQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formData = {
      email: data.get('email'),
    };
    try {
      const user = await getIsActive({ email: formData.email }).unwrap();
      if (user && !user.is_active) {
        setErrMsg('No active user found with this email!');
        return 0;
      }
      await resetPasswordMutation({ email: formData.email }).unwrap();
      dispatch(updateEmailResendCountdown({ updated: 20 }));
      setErrMsg("");
      navigate("/email_sent/reset_password", { state: { email: formData.email } });
    } catch (err) {
      console.log(err)
      if ((!err?.data?.email) && (!err?.data?.detail) && (!err.data)) {
        setErrMsg('No Server Response');
      } else {
        if (err?.data?.email) {
          setErrMsg(err.data?.detail);
        } else if (err?.data?.email?.[0]) {
          setErrMsg(err?.data?.email?.[0]);
        } else {
          setErrMsg(err.data);
        }
      }
    }
  };

  // count down timer
  React.useEffect(() => {
    emailResendCountdown > 0 && setTimeout(() => dispatch(updateEmailResendCountdown({ updated: emailResendCountdown - 1 })), 1000);
  }, [emailResendCountdown]);

  return (
    <Box sx={{ top: '50%', pt: '20vh' }}>
      <Container maxWidth="xs" >
        <Card
          sx={{ px: 2, mt: 0, backgroundColor: "transparent", opacity: 1, boxShadow: 'none' }}
          elevation={0}
        >
          {/* <CardMedia component="img" height={55} image={logo} style={{ scale: '92%' }} /> */}
          <CardContent>
            <Grid container width="100%" justifyContent="flex-start">
              <Typography component="h4" variant="h5" fontWeight='bold'>
                Reset Password
              </Typography>
              <Typography my={1} fontSize={16} color="neutral.main">
                Enter your email to reset your password.
              </Typography>
            </Grid>
            <Box component="form" position='relative' noValidate onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                sx={{ my: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><EmailOutlined /></InputAdornment>,
                }}
              />
              {errMsg && (<Typography sx={{ my: -1, left: 0, fontSize: 12, position: 'absolute' }} color="ra_error.main">{errMsg}</Typography>)}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  background: theme.palette.primary.main,
                  mb: 1,
                  mt: 1.5,
                }}
                disabled={emailResendCountdown > 0}
              >
                Send
            </Button>
              {emailResendCountdown > 0 && <Typography fontSize={16} ml={1} color='cat_black.main'>
                after {emailResendCountdown} seconds.
              </Typography>}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PasswordReset
