import {
  Box,
} from "@mui/material";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { useDispatch, useSelector } from "react-redux";


function Home() {
  const dispatch = useDispatch();
  const publicInfo = useSelector((state) => state.auth.publicInfo);
  const [images, setImages] = React.useState([]);
  const imageDir = '/media/carousel/'

  React.useEffect(() => {
    if (publicInfo.carousel?.length > 0) {
      const items = publicInfo.carousel.map(n => ({
        original: imageDir + n,
        thumbnail: imageDir + n,
      }))
      setImages(items);
    }
  }, [publicInfo]);

  return (
    <Box p={5} >
      <ImageGallery items={images} thumbnailPosition='left' showPlayButton={false} showFullscreenButton={false} disableThumbnailScroll={true} showBullets={true} showThumbnails={false} />
    </Box>
  );
}

export default Home;
