import { useLocation, useNavigate, Navigate } from "react-router-dom"
import { Box, Typography, Button } from "@mui/material"

import logo from "../../assets/images/header-logo.svg"


const PasswordResetSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClickGoHome = () => {
    navigate('/login');
  };

  return (
    (location.state !== null && location.state !== undefined) ? <Box sx={{ top: '50%', pt: '20vh' }}>
      {/* <img src={logo} alt="logo" width="300" /> */}
      <Typography component="h4" variant="h5" color='neutral.main' fontWeight='bold' mt={4}>
        Your password has been successfully reset.
      </Typography>
      <Button sx={{ mt: 4 }} variant="contained" size='medium' position='relative' color="primary" onClick={handleClickGoHome}>
        Go to Login
      </Button>
    </Box> : <Navigate to="/login" replace />
  )
};

export default PasswordResetSuccess
