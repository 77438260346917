// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { API_SERVER } from '../../settings'
import { logOut, setCredentials } from '../auth/authSlice'
import { parseJwt } from '../utils/jwt'

const noAuthQuery = fetchBaseQuery({
    baseUrl: API_SERVER,
})

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: API_SERVER,
    prepareHeaders: (headers, { getState }) => {
        let token = getState().auth.access
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQueryWithAuth(args, api, extraOptions);

    if (result?.error?.status === 401) {
        // authentication failed, send refresh token to get new access token 
        console.log('sending refresh token');
        const refresh = Cookies.get('refresh');
        const refreshResult = await noAuthQuery({
            url: "/auth/jwt/refresh/",
            method: "POST",
            body: { refresh: refresh },
            credentials: 'include',
        }, api, extraOptions); // refreshResult = {refresh:'', access: ''}
        if (refreshResult?.data) {
            const userID = parseJwt(refresh).user_id;
            // store the new token 
            api.dispatch(setCredentials({ userID: userID, access: refreshResult.data.access, refresh: refreshResult.data.refresh }));
            // retry the original query with new access token 
            result = await baseQueryWithAuth(args, api, extraOptions);
            if (result?.error?.status === 401) api.dispatch(logOut());
        } else {
            api.dispatch(logOut());
        }
    }
    return result;
}

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
})
