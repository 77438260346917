import { Box, Button, Divider, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, Stack, Tab, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React from 'react';

import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { useLazyGetAllReturnRateQuery, useLazySearchPropertyQuery } from '../../features/invest/investAPI';
import MapMarker from './MapMarker';
import SearchBox from './SearchBox';

const ScrollableWrapper = styled.main`
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  position: relative;
`;

const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: yellow;
  opacity: 0.6;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
`;

function PropertyMap(props) {
    const { downRate } = props;
    const [getAllReturnRate,] = useLazyGetAllReturnRateQuery();
    const [searchProperty,] = useLazySearchPropertyQuery();
    const [allRate, setAllRate] = React.useState(null);
    const [searchRes, setSearchRes] = React.useState({});
    const [currentCenter, setCurrentCenter] = React.useState({ lat: 30.3579825, lng: -97.7499143 });
    const [currentZoom, setCurrentZoom] = React.useState(11);
    const [searchMark, setSearchMark] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    var infowindow;

    const handleSubmit = (type, value) => async () => {
        try {
            if (Boolean(value)) {
                const res = await searchProperty({ type: type, value: value }).unwrap();
                if (res.state === 1) {
                    const data = JSON.parse(res.data);
                    setSearchRes(data);
                }
            } else setSearchRes({});
        } catch (e) {
            if (e.status === 403) alert('The trial has ended for the account.');
            console.log('Could not find property!')
        }
    }

    const handleSearchMarkerClick = (n) => (e) => {
        setSearchMark({ ...searchRes[n], ID: n });
        setAnchorEl(e.currentTarget);
    }

    const generateSearchMarkers = () => {
        // only show first 20 results
        return (
            Object.keys(searchRes).slice(0, 20)?.map(n => (<StyledMarker key={n} lat={searchRes[n].lat} lng={searchRes[n].lng} onClick={handleSearchMarkerClick(n)} />))
        )
    }

    const queryAllReturnRate = async () => {
        try {
            const res = await getAllReturnRate({ downRate: downRate }).unwrap();
            setAllRate(JSON.parse(res.data))
        } catch (e) {
            if (e.status === 403) alert('The trial has ended for the account.');
            console.log(e)
        }
    }

    React.useEffect(() => {
        queryAllReturnRate();
    }, [downRate]);

    React.useEffect(() => {
        if (searchRes && Object.keys(searchRes)?.length > 0) {
            setCurrentCenter({ lat: parseFloat(searchRes[Object.keys(searchRes)[0]].lat), lng: parseFloat(searchRes[Object.keys(searchRes)[0]].lng) });
        }
    }, [searchRes]);

    const getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds(new maps.LatLng(currentCenter.lat - 0.5, currentCenter.lng - 0.5), new maps.LatLng(currentCenter.lat + 0.5, currentCenter.lng + 0.5));

        Object.keys(places).forEach((i) => {
            bounds.extend(new maps.LatLng(
                places[i]["Latitude"],
                places[i]["Longitude"],
            ));
        });
        return bounds;
    };

    // Re-center map when resizing the window
    const bindResizeListener = (map, maps, bounds) => {
        window.addEventListener('resize', () => {
            map.fitBounds(bounds);
        });
    };

    const apiIsLoaded = (map, maps, places) => {
        function addMarker(p) {
            var latLng = new maps.LatLng(p["Latitude"], p["Longitude"]);
            var marker = new maps.Marker({
                position: latLng,
                map: map,
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    fillColor: p["color"],
                    fillOpacity: .5,
                    scale: p["mag"],
                    strokeColor: 'white',
                    strokeWeight: 0.5
                },
                label: p["Rate"]
            });

            marker.addListener('click', function () {
                if (infowindow) infowindow.close();
                var iw = new maps.InfoWindow({
                    content: '<p style="text-align:start;">' + p["content"] + '</p>'
                });
                iw.open(map, marker);
                infowindow = iw
                maps.event.addListener(map, "click", function (event) {
                    infowindow.close();
                });
            });

            return marker
        }
        Object.keys(places).forEach(i => {
            addMarker(places[i]);
        })

        // Get bounds by our places
        const bounds = getMapBounds(map, maps, places);
        // Fit map to bounds
        map.fitBounds(bounds);
        // Bind the resize listener
        bindResizeListener(map, maps, bounds);
    };

    return (
        <>
            <ScrollableWrapper>
                {allRate && Object.keys(allRate).length >= 0 && <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                    defaultCenter={currentCenter}
                    center={currentCenter}
                    defaultZoom={currentZoom}
                    options={{
                        streetViewControl: false,
                        draggable: true, // make map draggable
                        zoomControlOptions: { position: 9 },
                        keyboardShortcuts: false, // disable keyboard shortcuts
                        scaleControl: true, // allow scale controle
                        scrollwheel: true, // allow scroll wheel
                        mapTypeControl: true, // allow map type control
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, allRate)}
                >
                    {generateSearchMarkers()}
                </GoogleMapReact>}
                <SearchBox handlesubmit={handleSubmit} sx={{ position: 'absolute', left: 0, top: '50%', zIndex: 100 }} />
            </ScrollableWrapper>

            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <strong>PropertyID: {searchMark.ID}</strong><br />
                <li>Address: {searchMark.Address}</li>
                <li>2019 Property Tax Rate: {searchMark['2019Rate']}</li>
                <li>2019 Appraisal: {searchMark['2019']}</li>
                <li>2018 Appraisal: {searchMark['2018']}</li>
                <li>2017 Appraisal: {searchMark['2017']}</li>
                <li>2016 Appraisal: {searchMark['2016']}</li>
                <li>2015 Appraisal: {searchMark['2015']}</li>
            </Menu >
        </>
    )
}

export default PropertyMap