import { Stack, Typography } from "@mui/material"
import React from 'react'

function Contact() {
    return (
        <Stack direction='column' sx={{ px: 5, pt: 5, width: '100%', alignItems: "flex-start" }}>
            <Typography variant='h4'>Contact</Typography>
            <Typography variant='h5' mt={3} mb={2}>Wei Dai, Realtor</Typography>
            <Typography variant='body1'>13284 Pond Springs Road Suite 404</Typography>
            <Typography variant='body1'>Austin, TX, 78729</Typography>
            <Typography fontWeight='bold' variant='body1' my={2}>Marketing: <a href="mailto: wd4home@gmail.com">wd4home@gmail.com</a></Typography>
        </Stack>
    )
}

export default Contact