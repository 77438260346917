import React from 'react';
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { logOut } from '../../features/auth/authSlice';


const RemoveAuth = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        // Always remove credentials before accessing authentication related pages
        dispatch(logOut());
    }, [dispatch]);

    return (
        <Outlet />
    )
};

export default RemoveAuth;
