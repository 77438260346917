import React from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Stack, Typography, Button } from "@mui/material"

import logo from "../../assets/images/header-logo.svg"
import { updateEmailResendCountdown } from "../../features/auth/authSlice"
import { useResendActivationEmailMutation } from "../../features/auth/authAPI"


function ActivationEmailSent() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailResendCountdown = useSelector((state) => state.auth.emailResendCountdown);
  const [resendActivationEmailMutation] = useResendActivationEmailMutation();
  const handleClickGoHome = () => {
    navigate('/login');
  };
  const handleClickResend = async () => {
    await resendActivationEmailMutation({ email: location.state?.email }).unwrap();
    dispatch(updateEmailResendCountdown({ updated: 20 }));
  };

  // count down timer
  React.useEffect(() => {
    emailResendCountdown > 0 && setTimeout(() => dispatch(updateEmailResendCountdown({ updated: emailResendCountdown - 1 })), 1000);
  }, [emailResendCountdown]);

  return (
    (location.state !== null && location.state !== undefined) ? <Box sx={{ top: '50%', pt: '20vh' }}>
      {/* <img src={logo} alt="logo" width="300" /> */}
      <Typography component="h4" variant="h5" color='neutral.main' fontWeight='bold' my={3}>
        Check your email
      </Typography>
      <Typography variant="h6" color='neutral.main'>
        We have emailed an activation link to
      </Typography>
      <Typography variant="h6" color='neutral.dark' fontWeight='bold' my={2}>
        {location.state?.email}
      </Typography>
      <Typography variant="h6" color='neutral.main'>
        Click the link to complete creating your account.
      </Typography>
      <Stack direction='row' sx={{ mt: 4 }} justifyContent="center">
        <Typography fontSize={16} color='cat_black.main'>
          Did not receive email?
        </Typography>
        <Button disabled={emailResendCountdown > 0} sx={{ ml: 1, mt: -0.5, fontSize: 14 }} variant='outlined' size='small' color="cat_black" onClick={handleClickResend}>
          Resend
        </Button>
        {emailResendCountdown > 0 && <Typography fontSize={16} ml={1} color='cat_black.main'>
          after {emailResendCountdown} seconds.
        </Typography>}
      </Stack>
      <Button sx={{ mt: 4 }} variant="contained" size='medium' position='relative' color="primary" onClick={handleClickGoHome}>
        Go to Login
      </Button>
    </Box> : <Navigate to="/login" replace />
  )
};

export default ActivationEmailSent

