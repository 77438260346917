import React from 'react'
import { Button, Typography, Box } from "@mui/material"
import { useNavigate } from 'react-router-dom'

import logo from "../../assets/images/header-logo.svg"

function NotFound() {
    const navigate = useNavigate();
    const handleClickGoHome = () => {
        navigate('/login');
    };

    return (
        <Box sx={{ top: '50%', pt: '20vh' }}>
            {/* <img src={logo} alt="logo" width="300" /> */}
            <Typography sx={{ mt: 2 }} variant="h1" color="ra_error.main">
                404
            </Typography>
            <Typography sx={{ mt: 2 }} variant="h4" color="ra_error.main">
                This site can't be reached
            </Typography>
            <Button sx={{ mt: 5, width: 160 }} variant="contained" size='large' color="primary" onClick={handleClickGoHome}>
                Go to Login
            </Button>
        </Box>
    )
}

export default NotFound
