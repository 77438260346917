import { EmailOutlined, GroupsOutlined, LockOutlined, PersonOutlineOutlined } from "@mui/icons-material"
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Container, Divider, Grid, InputAdornment, TextField, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import logo from "../../assets/images/header-logo.svg"
import { useCreateUserMutation, useLazyGetIsActiveQuery } from "../../features/auth/authAPI"


const SignUp = () => {
  const theme = useTheme();
  const [errMsg, setErrMsg] = useState("");
  const [errData, setErrData] = useState({});
  const navigate = useNavigate();

  const [createUserTrigger, { isLoading: isLoadingSignUp }] = useCreateUserMutation();
  const [getIsActive] = useLazyGetIsActiveQuery();

  const handleClickSignIn = () => {
    navigate('/login');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setErrData({});
    const data = new FormData(e.currentTarget);
    const formData = {
      name: data.get('firstName') + ' ' + data.get('lastName'),
      email: data.get('email'),
      organization: data.get('organization'),
      password: data.get('password'),
      re_password: data.get('re_password'),
    };
    try {
      await createUserTrigger(formData).unwrap();
      navigate("/email_sent/activate", { state: { email: formData.email } });
    } catch (err) {
      console.log(err)
      if (!err?.data) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg(err.data?.detail);
        setErrData(err.data);
        // check if user already exists but activation failed
        if (err.data.email) {
          const user = await getIsActive({ email: formData.email }).unwrap();
          if (user && !user.is_active) {
            setErrMsg('');
            setErrData({});
            navigate("/email_sent/activate", { state: { email: formData.email } });
            return 0;
          }
        }
      }
    }
  };

  return (
    <Box sx={{ top: '50%', pt: '20vh' }}>
      <Container maxWidth="xs" >
        <Typography sx={{ fontSize: 30, fontWeight: 'bold' }} color="ra_black.main">Signup</Typography>
        <Card
          sx={{ px: 2, mt: 0, backgroundColor: "transparent", opacity: 1, boxShadow: 'none' }}
          elevation={0}
        >
          <CardContent>
            <Divider orientation="horizontal" flexItem sx={{ borderRightWidth: 2, mb: 5 }} />
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Box component="form" noValidate onSubmit={handleSubmit} position='relative'>
                <Grid container spacing={2.5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><PersonOutlineOutlined /> </InputAdornment>,
                      }}
                    />
                    {errData?.name && (<Typography sx={{ mt: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.name}</Typography>)}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><PersonOutlineOutlined /> </InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><EmailOutlined /> </InputAdornment>,
                      }}
                    />
                    {errData?.email && (<Typography sx={{ mt: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.email}</Typography>)}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="organization"
                      label="Organization"
                      name="organization"
                      autoComplete="organization"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><GroupsOutlined /></InputAdornment>,
                      }}
                    />
                    {errData?.organization && (<Typography sx={{ mt: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.organization}</Typography>)}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><LockOutlined /> </InputAdornment>,
                      }}
                    />
                    {errData?.password && (<Typography sx={{ mt: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.password}</Typography>)}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="re_password"
                      label="Confirm Password"
                      type="password"
                      id="re_password"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><LockOutlined /> </InputAdornment>,
                      }}
                    />
                    {errData?.re_password && (<Typography sx={{ mt: 0, fontSize: 13, position: 'absolute' }} color="ra_error.main">{errData.re_password}</Typography>)}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    background: theme.palette.primary.main,
                    mt: 2.5, mb: 0
                  }}
                  disabled={isLoadingSignUp}
                >
                  Sign Up
                </Button>
                {isLoadingSignUp && (<CircularProgress size={24} sx={{
                  color: 'primary.main',
                  position: 'absolute',
                  top: 398,
                  left: 162,
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
                />)}
                {errMsg && (<Typography sx={{ mb: 0, fontSize: 13 }} color="ra_error.main">{errMsg}</Typography>)}
                {errData.non_field_errors && (<Typography sx={{ fontSize: 13 }} color="ra_error.main">{errData.non_field_errors}</Typography>)}
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      disableRipple
                      size="small"
                      color="neutral"
                      onClick={handleClickSignIn}
                      sx={{ mb: -1 }}
                    >Already have an account? Sign in</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default SignUp
